import { Component } from "react";

const mapLink = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d423283.4355669374!2d-118.69192993092697!3d34.02073049448939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c75ddc27da13%3A0xe22fdf6f254608f4!2sLos%20Angeles%2C%20CA%2C%20USA!5e0!3m2!1sen!2sbd!4v1633958856057!5m2!1sen!2sbd";

class GoogleMap extends Component {
    render() { 
        return (
            <div id="map">
                <iframe src={mapLink} loading="lazy"></iframe>
            </div>
        );
    }
}
 
export default GoogleMap;